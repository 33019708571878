<template>
  <div>
    <b-card v-if="d_showSystemDoc" class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-nav tabs>
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item active v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <br>
      <b-row>
        <b-col cols="12">
          <b-button block variant="success" @click="f_saveWisdomEraSystemDocument()"><i class="fa fa-save"></i> Dökümanı Kaydet</b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Sistem İsmi Yönetimi
            </div>
            <b-row>
              <b-col cols="12">
                <b-input-group prepend="Sistemin İsmini Giriniz" label-for="system_name" label-cols="6" validated>
                  <b-form-input type="text" class="form-control-warning" id="system_name" v-model="d_wisdomeraSystem.system_name" required></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>
                      örn; WisdomEra Yapay Zeka
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Parameter Function Yönetimi
              <b-button class="pull-right" variant="primary" @click="f_add('parameter_functions')"><i class="fa fa-plus"></i> Yeni fonksiyon tanımla</b-button>
            </div>
            <b-row>
              <b-col sm="12" md="2">
                <strong>işlem</strong>
              </b-col>
              <b-col sm="12" md="4" class="text-center">
                <strong>value</strong>
              </b-col>
              <b-col sm="12" md="4" class="text-center">
                <strong>label</strong>
              </b-col>
            </b-row>
            <template v-for="(param_func, param_func_ind) in d_wisdomeraSystem.parameter_functions">
              <b-row :key="'parameter_functions_' + param_func_ind">
                <b-col sm="12" md="2">
                  <b-button small variant="primary" :disabled="param_func_ind === 0" @click="f_moveUp('parameter_functions', param_func_ind)"><i class="fa fa-arrow-up"></i></b-button>
                  <b-button small variant="primary" :disabled="param_func_ind === (d_wisdomeraSystem.parameter_functions.length - 1)" @click="f_moveDown('parameter_functions', param_func_ind)"><i class="fa fa-arrow-down"></i></b-button>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-input placeholder="value" type="text" class="form-control-warning" :id="'param_func_value_' + param_func_ind" v-model="param_func.value"></b-form-input>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-input placeholder="label" type="text" class="form-control-warning" :id="'param_func_label_' + param_func_ind" v-model="param_func.label"></b-form-input>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Dil Yönetimi
            </div>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Aktif Dil" label-for="active_lang" :label-cols="3" validated>
                  <b-form-select id="active_lang" :plain="true" v-model="d_wisdomeraSystem.active_lang" required>
                    <option v-for="(x, x_ind) in d_wisdomeraSystem.languages" :value="x">{{x.label}}</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-card header-tag="header" class="card-accent-primary">
              <div slot="header">
                Mevcut Diller
                <b-button class="pull-right" variant="primary" @click="f_add('languages')"><i class="fa fa-plus"></i> Yeni dil tanımla</b-button>
              </div>
              <b-row>
                <b-col sm="12" md="2">
                  <strong>işlem</strong>
                </b-col>
                <b-col sm="12" md="4" class="text-center">
                  <strong>value</strong>
                </b-col>
                <b-col sm="12" md="4" class="text-center">
                  <strong>label</strong>
                </b-col>
              </b-row>
              <template v-for="(language, language_ind) in d_wisdomeraSystem.languages">
                <b-row :key="'languages_' + language_ind">
                  <b-col sm="12" md="2">
                    <b-button small variant="primary" :disabled="language_ind === 0" @click="f_moveUp('languages', language_ind)"><i class="fa fa-arrow-up"></i></b-button>
                    <b-button small variant="primary" :disabled="language_ind === (d_wisdomeraSystem.languages.length - 1)" @click="f_moveDown('languages', language_ind)"><i class="fa fa-arrow-down"></i></b-button>
                    <!-- <b-button small variant="danger" :disabled="language.value === d_wisdomeraSystem.active_lang.value" @click="f_delete('languages', language_ind)"><i class="fa fa-trash"></i></b-button> -->
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-input placeholder="value" :disabled="language.value === d_wisdomeraSystem.active_lang.value" type="text" class="form-control-warning" :id="'language_value_' + language_ind" v-model="language.value"></b-form-input>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-input placeholder="label" :disabled="language.value === d_wisdomeraSystem.active_lang.value" type="text" class="form-control-warning" :id="'language_label_' + language_ind" v-model="language.label"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Timer Yönetimi
            </div>
            <b-input-group prepend="WisdomTimer fonksiyonlarının çalışacağı ip adresini girin" label-for="wisdom_timer.ip" label-cols="6" validated>
              <b-form-input type="text" class="form-control-warning" id="wisdom_timer.ip" v-model="d_wisdomeraSystem.wisdom_timer.ip" required></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-form-invalid-feedback>Lütfen IP adresi girin</b-form-invalid-feedback>
              <b-input-group-append>
                <b-input-group-text>
                  Mevcut Server IP Adresi: {{ d_currentServerIp }}
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Sistem Türü Seçimi
            </div>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Sistem Türü" label-for="active_lang" :label-cols="3" validated>
                  <b-form-select id="active_lang" :plain="true" v-model="d_wisdomeraSystem.system_type" required>
                    <option v-for="(x, x_ind) in d_systemTypes" :value="x.value">{{x.label}}</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Sistem URL'si
            </div>
            <b-input-group prepend="Sistemin URL'sini girin" label-for="system_url" label-cols="6" validated>
              <b-form-input type="text" class="form-control-warning" id="system_url" v-model="d_wisdomeraSystem.system_url" required></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-input-group-append>
                <b-input-group-text>
                  http protokolleri dahil, örnek: https://www.wisdomera.io
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Agreement
              <b-button class="pull-right" @click="f_showNewAgreement()">Agreement Ekle</b-button>
            </div>
            <template v-for="(agreement, agreement_ind) in d_wisdomeraSystem.agreement">
              <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
                <div slot="header">
                  {{ agreement.label }}
                  <b-button class="pull-right" @click="f_addNewAgreementVersion(agreement.value)">Version Ekle</b-button>
                </div>
                <template v-for="(agreement_version, agreement_version_ind) in agreement.list">
                  <b-row :key="'agreement_version_' + agreement_version_ind">
                    <b-col cols="6">
                      <b-input-group prepend="Agreement Date" label-for="agreement_version.date" label-cols="6">
                        <b-form-input placeholder="value" type="text" class="form-control-warning" :id="'agreement_version_date_' + agreement_version_ind" v-model="agreement_version.date"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col cols="6">
                      <b-input-group prepend="Agreement Version" label-for="agreement_version.version" label-cols="6">
                        <b-form-input placeholder="value" type="text" class="form-control-warning" :id="'agreement_version_version_' + agreement_version_ind" v-model="agreement_version.version"></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              Video List
              <b-button class="pull-right" variant="primary" @click="f_addNewVideoGroup()"><i class="fa fa-plus"></i> Yeni Video Grubu Ekle</b-button>
            </div>
            <template v-for="(video_group, video_group_ind) in d_wisdomeraSystem.web_video">
              <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
                <div slot="header">
                  <b-input-group prepend="Video Grup" label-for="video_group_list_name" label-cols="6">
                    <b-form-input placeholder="Video Group Başlığı" type="text" class="form-control-warning" :id="'video_group_list_name_' + video_group_ind" v-model="video_group.list_name"></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text>
                        <b-button size="sm" @click="f_addNewVideo(video_group_ind)">Video Ekle</b-button>
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group prepend="Video Grup Dil Türü" label-for="video_group.lang_type" label-cols="6">
                    <b-form-select id="video_group.lang_type" :plain="true" v-model="video_group.lang_type" required>
                      <option value="all">Hepsi</option>
                      <option value="selected">Seçilen</option>
                    </b-form-select>
                  </b-input-group>
                  <template v-if="video_group.lang_type === 'selected'">
                    <b-form-checkbox-group v-model="video_group.selected_lang" switches style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 5px;">
                      <template v-for="(x, x_ind) in d_wisdomeraSystem.languages">
                        <b-form-checkbox :id="'video_group_' + video_group_ind + '_' + x_ind" :value="x.value">{{ x.label }}</b-form-checkbox>
                      </template>
                    </b-form-checkbox-group>
                  </template>
                </div>
                <template v-for="(video, video_ind) in video_group.list">
                  <b-card :key="'video_' + video_ind" class="card-accent-primary" header-tag="header" footer-tag="footer">
                    <b-row>
                      <b-input-group prepend="Video Dil Türü" label-for="video.lang_type" label-cols="6">
                        <b-form-select id="video.lang_type" :plain="true" v-model="video.lang_type" required>
                          <option value="all">Hepsi</option>
                          <option value="selected">Seçilen</option>
                        </b-form-select>
                      </b-input-group>
                      <template v-if="video.lang_type === 'selected'">
                        <b-form-checkbox-group v-model="video.selected_lang" switches style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 5px;">
                          <template v-for="(x, x_ind) in d_wisdomeraSystem.languages">
                            <b-form-checkbox :id="'video_' + video_ind + '_' + x_ind" :value="x.value">{{ x.label }}</b-form-checkbox>
                          </template>
                        </b-form-checkbox-group>
                      </template>
                      <b-input-group prepend="Video Kaynağı" label-for="video.source_type" label-cols="6">
                        <b-form-select id="video.source_type" :plain="true" v-model="video.source_type" required>
                          <option value="youtube">YouTube</option>
                        </b-form-select>
                      </b-input-group>
                      <b-input-group prepend="Video Adı" label-for="video.name" label-cols="6">
                        <b-form-input placeholder="Video Adı" type="text" class="form-control-warning" :id="'video_name_' + video_ind" v-model="video.name"></b-form-input>
                      </b-input-group>
                      <b-input-group prepend="Video URL" label-for="video.url" label-cols="6">
                        <b-form-input placeholder="Video URL" type="text" class="form-control-warning" :id="'video_url_' + video_ind" v-model="video.url"></b-form-input>
                      </b-input-group>
                    </b-row>
                  </b-card>
                </template>
              </b-card>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button block variant="success" @click="f_saveWisdomEraSystemDocument()"><i class="fa fa-save"></i> Dökümanı Kaydet</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="d_showNewAgreement" v-model="d_showNewAgreement" title="Yeni Sözleşme Ekle" centered header-bg-variant="info" header-text-variant="dark">
      <b-row>
        <b-col cols="12">
          <b-input-group prepend="Agreement Label" label-for="d_newAgreementData.label" label-cols="6">
            <b-form-input type="text" class="form-control-warning" id="d_newAgreementData.label" v-model="d_newAgreementData.label"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-input-group prepend="Agreement Value" label-for="d_newAgreementData.value" label-cols="6">
            <b-form-input type="text" class="form-control-warning" id="d_newAgreementData.value" v-model="d_newAgreementData.value"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button variant="success" :disabled="!d_newAgreementData.value || !d_newAgreementData.label || d_wisdomeraSystem.agreement[d_newAgreementData.value]" @click="f_addNewAgreement()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showNewAgreement = false">{{ $t('wdm16.4053') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GlobalService from '@/services/global';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: "SystemManagement",
  computed: {
    ...mapGetters({
      lang: "lang"
    })
  },
  data () {
    return {
      user: {},
      d_showSystemDoc: false,
      d_wisdomeraSystem: {},
      d_currentServerIp: '',
      d_systemTypes: [
        { 'value': 'demo', 'label': 'Demo Sistem' },
        { 'value': 'developer', 'label': 'Geliştirici Sistemi / Local' },
        { 'value': 'customer', 'label': 'Müşteri Sistemi' },
        { 'value': 'mainserver', 'label': 'WisdomEra Merkez Sunucu' }
      ],
      d_showNewAgreement: false,
      d_newAgreementData: { 'value': '', 'label': '' }
    };
  },
  mounted: function () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getWisdomeraSystemDoc();
  },
  methods: {
    f_addNewVideo: function (video_group_ind) {
      this.d_wisdomeraSystem.web_video[video_group_ind].list.push({ 'url': '', 'source_type': 'youtube', 'lang_type': 'all', 'selected_lang': [] });
      this.$forceUpdate();
    },
    f_addNewVideoGroup: function () {
      this.d_wisdomeraSystem.web_video.push({ 'list_name': '', 'list': [], 'lang_type': 'all', 'selected_lang': [] });
      this.$forceUpdate();
    },
    f_addNewAgreementVersion: function (agreement_value) {
      this.d_wisdomeraSystem.agreement[agreement_value].list.push({ 'date': '', 'version': '' });
      this.$forceUpdate();
    },
    f_addNewAgreement: function () {
      this.d_wisdomeraSystem.agreement[this.d_newAgreementData.value] = { 'label': this.d_newAgreementData.label, 'value': this.d_newAgreementData.value, 'list': [] };
      this.$forceUpdate();
      this.d_showNewAgreement = false;
    },
    f_showNewAgreement: function () {
      this.d_newAgreementData = { 'value': '', 'label': '' };
      this.d_showNewAgreement = true;
    },
    f_getWisdomeraSystemDoc: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistem dökümanı hazırlanıyor' } });
      GlobalService.get_one_cb_document({ 'bucket': 'default', 'key': 'wisdomera_system' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wisdomeraSystem = resp.data.result;
            if (!this.d_wisdomeraSystem.agreement) {
              this.d_wisdomeraSystem.agreement = {};
            }
            if (!this.d_wisdomeraSystem.web_video) {
              this.d_wisdomeraSystem.web_video = [];
            }
            this.d_showSystemDoc = true;
            this.f_getCurrentServerIp();
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error:', resp.data.message);
          }
        });
    },
    f_getCurrentServerIp: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistem IP Adresi Getiriliyor' } });
      GlobalService.get_server_ip()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_currentServerIp = resp.data.result;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_saveWisdomEraSystemDocument: function () {
      let data = {}
      data.bucket = 'default'
      data.key = 'wisdomera_system'
      data.data = this.d_wisdomeraSystem;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'kayıt işlemi gerçekleştiriliyor' } });
      GlobalService.push_one_cb_document(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wisdomeraSystem = resp.data.result;
            let tmp_systemDoc = JSON.parse(JSON.stringify(this.d_wisdomeraSystem));
            this.$delete(tmp_systemDoc, 'wisdom_timer');
            localStorage.setItem('wisdomera_system', JSON.stringify(tmp_systemDoc));
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            let modal_data = {
              'type': 'alert',
              'header_bg_variant': 'info',
              'header_text_variant': 'dark',
              'text': 'Kayıt işlemi başarıyla tamamlandı',
              'centered': true,
              'title': 'WisdomEra System Dökümanı Kayıt İşlemi Hakkında'
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error:', resp.data.message);
          }
        });
    },
    f_delete: function (type, ind) {
      this.d_wisdomeraSystem[type].splice(ind, 1);
      this.$forceUpdate();
    },
    f_moveUp: function (type, ind) {
      let tmp = this.d_wisdomeraSystem[type][ind];
      this.d_wisdomeraSystem[type][ind] = this.d_wisdomeraSystem[type][ind - 1];
      this.d_wisdomeraSystem[type][ind - 1] = tmp;
      this.$forceUpdate();
    },
    f_moveDown: function (type, ind) {
      let tmp = this.d_wisdomeraSystem[type][ind];
      this.d_wisdomeraSystem[type][ind] = this.d_wisdomeraSystem[type][ind + 1];
      this.d_wisdomeraSystem[type][ind + 1] = tmp;
      this.$forceUpdate();
    },
    f_add: function (type) {
      this.d_wisdomeraSystem[type].push({ 'value': '', 'label': '' });
      this.$forceUpdate();
    },
  },
  watch: {},
  components: {
    Modal
  }
};

</script>

<style type="text/css">


</style>

